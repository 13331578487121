.mainWrapper {
	padding: 40px 30px;
}

.title,
.titleComment,
.textRow,
.search__wrapper,
.articleText,
.articleTitle {
	margin-left: 0px !important;
	margin-right: 0px !important;
}

.search__wrapper {
	margin-top: 20px;
}

.search__wrapper a {
	text-decoration: none;
	color: var(--color-dark-gray);
}

.title {
	margin-top: 56px;
	margin-bottom: 40px;
	font: var(--gilroy-Medium-24);
}

.articleTitle {
	margin-top: 56px;
	margin-bottom: 28px;
	font: var(--gilroy-Medium-24);
}

.titleComment {
	font: var(--gilroy-Medium-16);
	margin: 0px 0px 28px 0px !important;
	padding: 0px !important;
	color: var(--color-grey);
}

.columnLeft,
.columnRight {
	padding: 0px !important;
	font: var(--gilroy-Medium-14);
}

.columnLeft {
	padding-right: 5vw !important;
}

.columnLeft p {
	margin: 14px 0;
}

.columnLeft ul, .columnLeft ol {
	margin: 16px 0;
}

.columnLeft h1,
.columnLeft h2,
.columnLeft h3,
.columnLeft h4,
.columnLeft h5 {
	margin-block-start: 1em;
	margin-block-end: 1em;
}

.columnLeft li {
	margin-left: 1.5em;
}

.textRow {
	padding: 12px 0px !important;
}

.section__wrapper {
	margin-bottom: 48px;
}

.text {
	font: var(--gilroy-Medium-16);
	line-height: 22.4px;
	letter-spacing: 1px;
}

.text ul {
	list-style: outside;
}

.text ol,
.text ul {
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 0;
	margin-inline-end: 0;
	padding-inline-start: 0;
}

.articlesList {
	letter-spacing: 1px;
	line-height: 16.98px;
}

.articlesList:hover {
	text-decoration: line-through;
}

.wayTitle {
	color: var(--color-grey100);
}

@media screen and (min-width: 768px) {
	.search__wrapper {
		margin-top: 0px;
	}
}

.section__wrapper a {
	text-decoration: none;
	color: var(--color-dark-gray);
}

.picWrapper {
	margin-top: 30px;
}

.picture {
	width: 100%;
}
