.form_container {
	padding-top: 48px;
	position: relative;
}

.form_wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 31px;
	padding-bottom: 20px;
	margin-bottom: 20px;
	min-width: 570px;
	border-bottom: 2px dashed rgba(0, 0, 0, 0.152);
}

.btn_container {
	display: flex;
	align-items: flex-end;
}

.btn_save {
	margin-top: 48px;
	margin-left: 0px;
	padding: 10px 30px;
	font: var(--gilroy-Medium-18-140);
	cursor: pointer;
	width: 328px;
	height: 47px;
	border: none;
	background-color: var(--color-btn-grey);
	color: var(--color-white100);
	letter-spacing: 1px;
}

.btn_editHide {
	position: absolute;
	display: flex;
	align-items: flex-end;
	right: 2%;
	top: 9%;
}

.btn__edit,
.btn__edit_disabled {
	border: none;
	background-color: inherit;
	display: flex;
	align-items: flex-end;
	font: var(--gilroy-Medium-16-19);
	text-decoration-line: underline;
	color: var(--color-dark-grey);
	margin-right: 27px;
}

.btn__edit {
	cursor: pointer;
}

.btn__edit_disabled {
	cursor: not-allowed;
	color: rgba(16, 16, 16, 0.3);
}

.edit__svg,
.edit__svg_disabled {
	width: 24px;
	height: 24px;
	margin-right: 8px;
}

.edit__svg_disabled {
	opacity: 0.5;
}

.remove_svg,
.remove_svg_disabled {
	border: none;
	background-color: inherit;
}

.remove_svg {
	cursor: pointer;
}

.remove_svg_disabled {
	cursor: not-allowed;
}

.delete_svg {
	position: absolute;
	border: none;
	background-color: inherit;
	right: 0;
	top: 55px;
	cursor: pointer;
}

.title {
	font: var(--gilroy-Medium-24-140p);
	letter-spacing: 1px;
	color: var(--color-dark-grey);
	margin-bottom: 28px;
}

.name_recording {
	display: inline-block;
	font: var(--gilroy-Medium-24-140p);
	letter-spacing: 1px;
	color: var(--color-dark-grey);
	width: 30vw;
	min-width: 350px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.list {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 24px;
	grid-row-gap: 24px;
}
.list_item > div {
	margin: 0;
	width: 100%;
}

.list_item {
	width: 100%;
}

.countryItem {
	display: flex;
	align-items: flex-end;
}

.description {
	padding: 6px 4px 0px;
	font: var(--gilroy-Medium-12-20);
	letter-spacing: 0.4px;
	color: rgba(0, 0, 0, 0.54);
}

.checkbox {
	margin-top: 35px;
}

.btn_add {
	border: none;
	background-color: inherit;
	display: flex;
	align-items: center;
	margin-top: 24px;
}

.add_svg {
	width: 24px;
	height: 24px;
}

.main_add_btn_text {
	font: var(--gilroy-Medium-14-17);
	text-decoration: underline;
	color: var(--color-dark-grey);
	margin-left: 8px;
}

.input {
	position: relative;
	width: 100%;
}

.helper {
	margin-top: 6px;
	display: block;
	color: var(--color-red);
	font: var(--gilroy-Medium-12-12);
	padding: 0 4px;
}

.main {
	padding-bottom: 20px;
	margin-bottom: 20px;
	border-bottom: 2px dashed rgba(0, 0, 0, 0.152);
}

.title_hover_hide {
	display: none;
	position: absolute;
	font: var(--gilroy-Medium-16);
}

.title_wrapepr:hover .title_hover_hide {
	display: block;
}

.error {
	color: var(--color-red);
	font: var(--gilroy-Medium-12-12);
	padding: 0 5px;
}

.catalog_number_container {
	display: flex;
	flex-direction: column;
}

.catalog_number > div {
	width: 100%;
}

.autoWrapper {
	display: flex;
	align-items: end;
	height: 100%;
}

.grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 24px;
}

.artistsBlock {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 48px;
}

.performerError > div > span {
	bottom: 0;
}

