.UserMenu {
	margin: 68px 0 40px;
	width: 100%;
	font: var(--gilroy-Medium-32);
}

.UserMenu a {
	color: var(--color-navbar-top-refs);
}

.UserMenu a:hover {
	color: var(--color-navbar-top-refs-hover);
}

.UserMenu a.activeLink {
	text-decoration: line-through;
	color: var(--color-navbar-top-refs-hover);
}

.UserMenu button {
	min-width: 100%;
	background-color: var(--color-light-green);
}

.UserMenu button span {
	display: flex;
	justify-content: space-evenly;
	width: 100%;
}

.UserMenu li {
	padding: 12px 0px;
}

.UserEmpty {
	height: 217px;
}

.NavBar {
	display: flex;
	font: var(--gilroy-Medium-16);
	overflow: hidden;
	flex-direction: column;
	justify-content: space-between;
	box-sizing: border-box;
	height: 100%;
	padding: 30px 0px;
	background-color: var(--color-dark-grey100);
	grid-area: navbar;
}

.NavBar a:hover {
	text-decoration: none;
}

.faq {
	margin-top: auto;
	cursor: pointer;
	font: var(--gilroy-Medium-16);
	line-height: 16px;
}

.faq a {
	color: var(--color-navbar-bottom-refs);
}

.faq a:hover {
	color: var(--color-navbar-bottom-refs-hover);
}

.faq a.activeLink {
	text-decoration: line-through;
	color: var(--color-navbar-bottom-refs-hover);
}

.Copyright {
	margin-top: 6px;
	margin-bottom: 10px;
	color: var(--color-copyright);
}

.Copyright * {
	font: var(--gilroy-Medium-11);
}

.HandlingWrapper {
	color: var(--color-grey100);
}

.HandlingHeadingWrapper {
	display: flex;
	align-items: center;
	cursor: pointer;
	color: var(--color-navbar-top-refs);
}

.HandlingHeadingWrapper span {
	margin-right: 8px;
}

.UserMenu .subHandlingItem {
	padding-top: 8px;
	padding-bottom: 8px;
}

.UserMenu .subHandlingList {
	padding-bottom: 4px;
}

.HandlingWrapper .SubHandlingWrapper > ul > li:last-child {
	padding-bottom: 0px;
}

.subHandlingItem a {
	padding-left: 8px;
}

.SubHandlingWrapper {
	padding-top: 12px;
}

@media screen and (min-width: 320px) {
	.NavBar {
		justify-content: center;
		background-color: transparent;
		font: var(--gilroy-Medium-24);
	}
}

@media screen and (min-width: 768px) {
	.UserMenu {
		margin: 0px;
		margin-bottom: 84px;
		font: inherit;
	}

	.Links {
		flex-direction: row;
		justify-content: space-around;
	}

	.faq li {
		padding: 8px 0px;
	}

	.Copyright {
		text-align: center;
		line-height: 13px;
		margin: 0px;
		padding: 20px 0px 24px;
	}
}

@media screen and (min-width: 819px) {
	.NavBar {
		display: flex;
		padding: 0px 16px 0px 24px;
		background-color: var(--color-navbar);
		font: var(--gilroy-Medium-16);
		line-height: 24px;
	}
}

@media screen and (min-width: 1024px) {
	.Links {
		flex-direction: column;
		justify-content: space-between;
		margin-top: auto;
	}

	.Copyright {
		text-align: left;
	}

	.UserMenu {
		display: block;
	}
}

@media screen and (min-width: 3840px) {
	.NavBar {
		padding: 60px 50px;
	}
}
