.title {
	display: inline-block;
	margin-bottom: 24px;
	letter-spacing: 1px;
	font: var(--gilroy-Medium-24-140p);
}

.titleWrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.titleWrapper > :nth-child(2) {
	display: flex;
}

.titleWrapper > :nth-child(2) > :nth-child(2) {
	margin-left: 8px;
	text-decoration: underline;
	font: var(--gilroy-Medium-18);
}

.table {
	display: flex;
	align-items: center;
	height: 52px;
	padding: 0 24px;
}

.table__header {
	align-items: flex-end;
	color: var(--color-grey100);
	padding-top: 0;
	padding-bottom: 16px;
	font: var(--gilroy-Medium-14);
}

.table__body > * {
	margin-bottom: 2px;
}

.table__body > *:last-child {
	margin-bottom: 0;
}

.item__performersWrapper {
	position: relative;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.item__performers:first-child {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.item__performers:nth-child(2) {
	position: absolute;
	left: 0;
	bottom: 115%;
	padding: 3px;
	background: var(--color-dark-grey);
	color: var(--color-grey100);
	opacity: 0;
	visibility: hidden;
	z-index: 1;
	transition: 0.1s ease;
}

.item__performersWrapper:hover > * {
	opacity: 1;
	visibility: visible;
}

.item__number {
	text-align: center;
}

.grid {
	display: grid;
	grid-template-columns: 48px 3fr 1fr 1fr 2fr 2fr 1fr 48px;
	column-gap: 10px;
}

.table__item {
	font: var(--gilroy-Medium-16);
	background: var(--color-white);
	position: relative;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.table__item span {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.item__change {
	display: flex;
	justify-content: center;
}

.play {
	display: block;
	margin-left: auto;
	width: 20px;
	height: 20px;
}
